<script lang="ts">
    import '../../../public/css/pages/lists.css';
    import Modal from '../components/Modal.svelte';
    import { inertia, router } from '@inertiajs/svelte';
    import { Icon, Plus } from 'svelte-hero-icons';
    import { Input } from '$lib/components/ui/input';
    import axios from 'axios';

    export let lists;
    export let Cookie;
    let listName = '';

    const updateList = async (type: string) => {
        const data = {
            name: listName,
            type: type,
        };
        const response = await axios.post('/my/lists/save', data);
        if (response && response.status === 200) {
            launchToast('success', trans('Success'), trans('List added') + '.');
            router.reload({ only: ['lists'] });
            listName = '';
        }
    };
</script>

<div class="row">
    <div class="min-vh-100 border-right col-12 pr-md-0">
        <div class="d-flex justify-content-between align-items-center border-bottom px-3 pb-3 pt-4">
            <div>
                <h5 class="text-truncate mb-0 font-bold {Cookie}">Lists</h5>
            </div>
            <Modal title="Create a new list" onConfirm="{() => updateList('create')}" confirmText="Save">
                <button
                    class="btn btn-outline-primary btn-sm mb-0 px-3"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Add list"
                    slot="trigger"
                >
                    <Icon src="{Plus}" size="{'15px'}" />
                </button>
                <Input
                    id="list-name"
                    type="text"
                    class="form-control"
                    name="text"
                    required
                    placeholder="List name"
                    bind:value="{listName}"
                />
            </Modal>
        </div>
        <div class="lists-wrapper mt-2" id="lists">
            {#each lists as list, index}
                <div class="px-2">
                    <a
                        href="{list.type !== 'follower' ? `/my/lists/${list.id}` : `/my/lists/follower`}"
                        use:inertia
                        class="list-link d-flex flex-column rounded pb-2 pl-3 pt-2"
                    >
                        <div class="d-flex flex-row-no-rtl justify-content-between">
                            <div>
                                <h5 class="mb-1 font-bold">{list.name}</h5>
                                <span class="text-muted">
                                    {list.members.length}
                                    {list.members.length === 1 ? 'Person' : 'People'} - {list.posts_count}
                                    {list.posts_count === 1 ? 'Post' : 'Posts'}
                                </span>
                            </div>
                            <div class="d-flex align-items-center list-box-avatars-wrapper justify-end pr-3">
                                {#each list.members.slice(0, 3) as member}
                                    <img src="{member.avatar}" class="rounded-circle user-avatar w-2/5" alt="" />
                                {/each}
                            </div>
                        </div>
                    </a>
                </div>
                {#if index !== lists.length - 1}
                    <hr class="my-2" />
                {/if}
            {/each}
        </div>
    </div>
</div>
